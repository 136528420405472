import {
  Component,
  ChangeDetectionStrategy,
  input,
  inject
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ZefReactiveComponent } from '@zerops/zef/core';
import {
  PipelineDebugModes,
  PipelineDebugPhases,
  PipelineDebugPhasesUserDataKeys,
  ServiceStackEntity,
  pipelineDebug
} from '@zerops/zerops/core/service-stack-base';
import { UserDataEntity } from '@zerops/zerops/core/user-data-base';
import {
  Subject,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { FEATURE_NAME } from './debug-switcher.constant';
import { selectZefProgressByType } from '@zerops/zef/progress';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'z-debug-switcher',
  templateUrl: './debug-switcher.feature.html',
  styleUrls: [ './debug-switcher.feature.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebugSwitcherFeature extends ZefReactiveComponent {

  // # Deps
  #serviceStackEntity = inject(ServiceStackEntity);
  #userDataEntity = inject(UserDataEntity);
  #store = inject(Store);

  // # Event Streams
  onSetDebug$ = new Subject<{
    phase: PipelineDebugPhases;
    mode: PipelineDebugModes
  }>();

  // # Data
  // -- sync
  debugPhases = PipelineDebugPhases;
  debugModes = PipelineDebugModes;
  debugPhasesKeys = PipelineDebugPhasesUserDataKeys;
  options = [
    {
      label: 'Disable',
      mode: this.debugModes.NONE
    },
    {
      label: 'Before first command',
      mode: this.debugModes.BEFORE
    },
    {
      label: 'After last command',
      mode: this.debugModes.AFTER
    },
    {
      label: 'On command fail',
      mode: this.debugModes.ON_FAIL
    }
  ];

  // -- angular
  serviceId = input<string>();
  serviceId$ = toObservable(this.serviceId);

  // -- async
  service$ = this.serviceId$.pipe(
    filter((d) => !!d),
    switchMap((id) => this.#serviceStackEntity.entityById$(id)),
    filter((d) => !!d)
  );
  pipelineDebugUserDataMap$ = this.serviceId$.pipe(
    filter((d) => !!d),
    switchMap((id) => this.#userDataEntity.list$(
      { name: FEATURE_NAME, id }
    ).pipe(
      map((data) => data?.reduce((result, item) => {
        if (
          item.key === this.debugPhasesKeys.DEBUG_BUILD ||
          item.key === this.debugPhasesKeys.DEBUG_RUNTIME_PREPARE
        ) {
          result[item.key as PipelineDebugPhasesUserDataKeys] = item.content as PipelineDebugModes;
        }
        return result;
        }, {
          [this.debugPhasesKeys.DEBUG_BUILD]: undefined,
          [this.debugPhasesKeys.DEBUG_RUNTIME_PREPARE]: undefined
        } as Record<PipelineDebugPhasesUserDataKeys, PipelineDebugModes>
      ))
    ))
  );
  isLoading$ = this.#store.pipe(
    select(selectZefProgressByType(pipelineDebug.type))
  );

  // # State resolver
  state = this.$connect({
    pipelineDebugUserDataMap: this.pipelineDebugUserDataMap$,
    isLoading: this.isLoading$
  });

  constructor() {
    super();

    this.$dispatchActions([

      this.onSetDebug$.pipe(
        withLatestFrom(toObservable(this.serviceId)),
        map(([ { phase, mode }, id ]) => pipelineDebug(
          { id, phase, mode },
          undefined,
          { meta: phase }
        ))
      ),

      this.service$.pipe(
        filter((d) => !!d),
        distinctUntilKeyChanged('id'),
        map(({ id, project }) => this.#userDataEntity.listSubscribe(
          project.clientId,
          {
            name: FEATURE_NAME,
            id
          },
          {
            search: [
              {
                name: 'serviceStackId',
                operator: 'eq',
                value: id
              }
            ]
          }
        ))

      )
    ]);

  }
}
